import React from "react";
import "../css/Style.css";
import Galleryimg1 from "../assets/img/galleryimg1.webp";
import Galleryimg2 from "../assets/img/galleryimg2.webp";
import Galleryimg3 from "../assets/img/galleryimg3.webp";
function Gallery() {
  return (
    <>
      <div className="bg-black">
        <div className="container">
          <div className="flex justify-center items-center gap-5 lg:pt-[80px] md:pt-[0px] sm:pt-[20px] pt-[50px]">
            <p className="bg-[#95C941] w-[6px] h-[46px] rounded-xl"></p>
            <p className="text-[#F2F2F2] lg:text-[48px] md:text-[48px] sm:text-[35px] text-[35px] font-medium ">
              Gallery
            </p>
          </div>

          <div class="grid grid-cols-2 gap-4 mt-5">
            <div class="col-span-2">
              <img src={Galleryimg1} alt="First Image" class="w-full" />
            </div>

            <div class="col-span-1">
              <img
                src={Galleryimg2}
                alt="Second Image (Large)"
                className="w-full"
              />
            </div>
            <div class="col-span-1">
              <img
                src={Galleryimg3}
                alt="Third Image (Small)"
                className="w-full galleryimg3 object-cover  lg:h-[320px] md:h-[185px] sm:h-[150px]  h-[107px]"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery;
