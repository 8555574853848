import React from "react";
// import Bannerlogo from "../assets/img/banner-logo.png"
function Banner() {
  return (
    <>
      <div className="bg-black" id="about">
        <div className="container">
          {/* <div className='flex justify-end items-center '>
        <img src={Bannerlogo} alt='logobanner'>

        </img> */}
          {/* </div> */}
          <div className="flex justify-center items-center">
            <div>
              <div className="flex justify-center items-center gap-5  lg:pt-[140px] pt-[30px] lg:pb-[0px] pb-[30px]">
                <p className="bg-[#95C941] w-[6px] h-[46px] rounded-xl"></p>
                <p className="text-[#F2F2F2] lg:text-[48px] md:text-[48px] sm:text-[25px] text-[25px] font-medium ">
                  Welcome to 360 View
                </p>
              </div>
              <p className="text-[#DFDFDF] pl-[10px] pr-[10px] capitalize text-center font-normal text-[18px] m-3 leading-7 lg:w-[975px] md:w-[705px] sm:w-[385px] w-[385px]">
                Welcome to 360 View.ai, where immersive storytelling meets
                cutting-edge technology. We are your premier destination for
                captivating 360 view photography that transcends traditional
                visual experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
