import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderimg_1 from "../assets/img/sliderimg_1.webp";
import sliderimg_2 from "../assets/img/sliderimg_2.webp";
import "../css/Style.css";
function slider() {
  const settings = {
    // dots: false,
    // infinite: true,
    speed: 500,
    slidesToShow: 2,
  };
  return (
    <div className="">
      <Slider {...settings} className="client_slider">
        <div className="m-0 p-0">
          <img src={sliderimg_1} alt="sliderimg_1" />
        </div>

        <div className="m-0 p-0">
          <img src={sliderimg_2} alt="sliderimg_2" />
          <div className=""></div>
        </div>
        <div>
          <img src={sliderimg_1} alt="sliderimg_1" />
          <div className=""></div>
        </div>
        <div>
          <img src={sliderimg_2} alt="sliderimg_2" />
          <div className=""></div>
        </div>
        <div>
          <img src={sliderimg_1} alt="sliderimg_1" />
          <div className=""></div>
        </div>
        <div>
          <img src={sliderimg_2} alt="sliderimg_2" />
          <div className=""></div>
        </div>
      </Slider>
    </div>
  );
}

export default slider;
