import "./App.css";
import Homepage from "./component/Home.jsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./component/About.jsx";
import Services from "./component/Services.jsx";
import Contact from "./component/Contact.jsx";
import { useRef } from "react";
function App() {
  return (
    <div className="App">
      {/* <BrowserRouter>
  <Homepage />
        </BrowserRouter> */}

      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
