import React from "react";
import "../css/Style.css";
import equipment_logo from "../assets/img/equipment_logo.webp";
import expertise_logo from "../assets/img/expertise_logo.webp";
import Bg_choose_us from "../assets/img/bg-choose-us.webp";
import CircularProgressBar from "../component/processbar";
import Feturespercentage1 from "../assets/img/feturespercentage1.webp";
import Feturespercentage2 from "../assets/img/feturespercentage2.webp";
import Feturespercentage3 from "../assets/img/feturespercentage3.webp";
function Features() {
  return (
    <>
      <div className="bg-black m-0">
        <div className="container">
          <div className="flex justify-start items-center gap-5 lg:pt-[80px] p-[30px]">
            <p className="bg-[#95C941] w-[6px] h-[46px] rounded-xl"></p>
            <p className="text-[#F2F2F2] lg:text-[48px] md:text-[48px] sm:text-[48px] text-[25px] font-medium ">
              Features
            </p>
          </div>
          <div className="text-[#DFDFDF] pl-5 capitalize text-start font-normal text-[18px] m-3 leading-7 lg:w-[1016px] md:w-[616px] sm:w-[400px] w-[330px]">
            360 view is the perfect tool for anyone who wants to create a
            realistic and interactive digital twin of their space. Whether
            you're a real estate agent, architect, or business owner, 360 view
            can help you visualize, understand, and manage your space in a whole
            new way.
          </div>
          <div className="lg:p-0 md:p-0 pl-5 pr-5">
            <div className="flex justify-around mt-[80px] ">
              <div className="">
                <p className="bg-[#95C941] w-[49px] h-[49px] shrink-0 m-auto flex items-center justify-center rounded-full text-white text-center text-[28px] italic leading-7 capitalize">
                  1
                </p>
                <p className="text-[#F2F2F2] font-[24px] font-medium leading-7 capitalize">
                  Immersive 360 degree experience
                </p>
              </div>

              <div className="">
                <p className="bg-[#95C941] w-[49px] h-[49px] shrink-0 m-auto flex items-center justify-center rounded-full text-white text-center text-[28px] italic leading-7 capitalize">
                  2
                </p>
                <p className="text-[#F2F2F2] font-[24px] font-medium leading-7 capitalize">
                  Metasoft Technology Integration
                </p>
              </div>
            </div>
            <div className="flex justify-around mt-[50px]">
              <div className="">
                <p className="bg-[#95C941] w-[49px] h-[49px] shrink-0 m-auto flex items-center justify-center rounded-full text-white text-center text-[28px] italic leading-7 capitalize">
                  3
                </p>
                <p className="text-[#F2F2F2] font-[24px] font-medium leading-7 capitalize">
                  Interactive Panorama
                </p>
              </div>

              <div className="">
                <p className="bg-[#95C941] w-[49px] h-[49px] shrink-0 m-auto flex items-center justify-center rounded-full text-white text-center text-[28px] italic leading-7 capitalize">
                  4
                </p>
                <p className="text-[#F2F2F2] font-[24px] font-medium leading-7 capitalize">
                  Seamless Virtual Tours
                </p>
              </div>
            </div>
          </div>
          <div className="lg:flex md:flex justify-around items-center  mt-[80px]">
            <div className="">
              <div className="flex justify-center">
                <img src={Feturespercentage1} alt="Feturespercentage1"></img>
              </div>
              <p className="text-[#F2F2F2] text-[24px] font-medium">
                Real Estate
              </p>
            </div>
            <div>
              <div className="flex justify-center mt-5">
                <img src={Feturespercentage2} alt="Feturespercentage1"></img>
              </div>
              <p className="text-[#F2F2F2] text-[24px] font-medium">
                Commercial Real Estate
              </p>
            </div>
            <div>
              <div className="flex justify-center mt-5">
                <img src={Feturespercentage3} alt="Feturespercentage1"></img>
              </div>
              <p className="text-[#F2F2F2] text-[24px] font-medium">
                Travel and Hospitality
              </p>
            </div>
          </div>

          {/* <div className="all lg:flex md:flex justify-center">
  <div className="circle-wrap">
    <div className="circle">
      <div className="mask full-1">
        <div className="fill-1 gradient-1"></div>
      </div>
      <div className="mask half">
        <div className="fill-1 gradient-1"></div>
      </div>
      <div className="inside-circle">74%</div>
    </div>
  </div>
  <div className="circle-wrap">
    <div className="circle">
      <div className="mask full-2">
        <div className="fill-2 gradient-2"></div>
      </div>
      <div className="mask half">
        <div className="fill-2 gradient-2"></div>
      </div>
      <div className="inside-circle">85%</div>
    </div>
  </div>
  <div className="circle-wrap">
    <div className="circle">
      <div className="mask full-3">
        <div className="fill-3 gradient-3"></div>
      </div>
      <div className="mask half">
        <div className="fill-3 gradient-3"></div>
      </div>
      <div className="inside-circle">14%</div>
    </div>
  </div>
</div> */}
        </div>
        <div>
          <div className="Bg_choose_us pt-[30px] lg:pb-[60px] md:pb-[60px] pb-[30px] lg:pl-[60px] md:pl-[60px] pl-[30px]">
            {/* <img src={Bg_choose_us} alt='bg-choose-us' className='w-full lg:h-full  md:h-[850px] sm:h-[850px] h-[1100px] ' ></img> */}
            <div className="container">
              <div className="flex justify-start items-center  gap-5 mt-[50px]">
                <p className="bg-[#95C941] w-[6px] h-[46px] rounded-xl"></p>
                <p className="  text-[#F2F2F2] lg:text-[48px] text-[35px] font-medium ">
                  Why Choose Us ?
                </p>
              </div>

              <div className="lg:flex lg:justify-start  mt-[40px] p-5">
                <div className="lg:w-[542px]">
                  <img src={equipment_logo}></img>
                  <p className="text-white flex justify-start p-3 mt-2 font-semibold leading-7 text-[18px] capitalize">
                    state-of-the-art equipment
                  </p>
                  <p className="text-white text-start p-3 font-normal leading-8 text-[18px] lg:w-full md:w-full sm:w-full w-[350px] capitalize">
                    State-of-the-art equipment signifies cutting-edge technology
                    at its zenith, embodying innovation and superior
                    performance. These advanced tools, incorporating the latest
                    breakthroughs, redefine industries with precision,
                    efficiency, and unparalleled capabilities
                  </p>
                </div>
                <div className="lg:w-[542px]">
                  <img src={expertise_logo}></img>
                  <p className="text-[#F2F2F2] flex justify-start p-3 mt-2 font-semibold leading-7 text-[18px] capitalize ">
                    Our expertise
                  </p>
                  <p className="text-[#F2F2F2] text-start p-3 font-normal leading-8 lg:w-full md:w-full sm:w-full w-[350px] text-[18px] capitalize">
                    {" "}
                    With a wealth of knowledge and skill, our expertise lies in
                    navigating complex challenges. We bring innovative
                    solutions, strategic insights, and a proven track record to
                    deliver excellence in every endeavor.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
