import React, { useState, useRef } from "react";
import Bg_img_contact from "../assets/img/bg-img-contact.webp";
import GoogleMap from "./GooleMap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import emailjs from "@emailjs/browser";
function Contact() {
  const notify = () => toast("Thank you for contact. We will contact Shortly");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const showToast = (message, type = "success") => {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000, // Close the toast after 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear validation error when user types
    setErrors({
      ...errors,
      [name]: undefined,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation checks
    const validationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      validationErrors.email = "Invalid email format";
    }
    if (!formData.mobile.trim()) {
      validationErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      validationErrors.mobile = "Invalid mobile number";
    }
    if (!formData.message.trim()) {
      validationErrors.message = "Message is required";
    }

    // Update errors state with validation errors
    setErrors(validationErrors);

    // If no validation errors, submit the form
    if (Object.keys(validationErrors).length === 0) {
      // Perform form submission logic here (e.g., send data to server)
      // console.log("Form submitted:", formData);
      console.log("we reached here", formData);
      emailjs
        .sendForm(
          "service_hycrds9",
          "template_lyu8o4m",
          formData.current,
          "p87NmtLp7L4bobmxM"
        )
        .then((response) => {
          console.log("Email sent successfully:", formData);
          showToast("Email sent successfully", "success");
        })

        .catch((error) => {
          console.error("Error sending email:", error);
          showToast("Error sending email", "error");
        });
    }
  };
  return (
    <>
      <div className="Bg_img_contact" id="contact">
        {/* <img src={Bg_img_contact} alt='bg-about' className="lg:h-full w-full sm:h-[1450px] h-[1450px]"></img> */}
        <div className="container">
          <div className="lg:pt-[60px]">
            <div className="flex justify-start items-center gap-5 lg:pt-[0px] md:pt-[0px] sm:pt-[10px] pt-[20px]">
              <p className="bg-[#95C941] w-[6px] h-[46px] rounded-xl"></p>
              <p className="  text-[#F2F2F2] lg:text-[48px] md:text-[48px] text-start text-[30px] flex items-center font-medium ">
                Let's Create Together
              </p>
            </div>
            <div className="lg:mt-[0px] md:mt-[0px] mt-[30px]">
              <p className="text-[#9B9B9B] text-[18px] font-normal capitalize lg:w-[711px] lg:h-[129px] md:w-[680px] md:h-[129px] w-full text-start">
                Whether you're unveiling a property, enhancing your e-commerce
                presence, or offering virtual tours, 360 View.ai is here to turn
                your vision into an immersive reality. Let's embark on a journey
                of visual innovation together.
              </p>
            </div>
            <div className="lg:flex justify-start gap-[100px] items-center">
              <div className="">
                <form ref={formData} onSubmit={handleSubmit}>
                  <ToastContainer />
                  <div className="mb-4">
                    <label
                      htmlFor="name"
                      className="text-[#F2F2F2] text-[15px] leading-7  font-medium mb-2 text-start flex justify-start"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="lg:w-[562px] md:w-full w-full text-white p-2 border-[1px] border-[#AFAFAF] bg-transparent rounded-md"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    <div className="text-start">
                      {errors.name && (
                        <span className="text-red-500">{errors.name}</span>
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="text-[#F2F2F2] text-[15px] leading-7  font-medium mb-2 text-start flex justify-start"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="lg:w-[562px] md:w-full w-full text-white p-2 border-[1px] border-[#AFAFAF] bg-transparent rounded-md"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    <div className="text-start">
                      {errors.email && (
                        <span className="text-red-500">{errors.email}</span>
                      )}
                    </div>
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="mobile"
                      className="text-[#F2F2F2] text-[15px] leading-7  font-medium mb-2 text-start flex justify-start"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      className="lg:w-[562px] md:w-full w-full text-white p-2 border-[1px] border-[#AFAFAF] bg-transparent rounded-md"
                      value={formData.mobile}
                      onChange={handleInputChange}
                    />
                    <div className="text-start">
                      {errors.mobile && (
                        <span className="text-red-500">{errors.mobile}</span>
                      )}
                    </div>
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="message"
                      className="text-[#F2F2F2] text-[15px] leading-7  font-medium mb-2 text-start flex justify-start"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      className="lg:w-[562px] h-[220px] md:w-full w-full p-2 text-white border bg-transparent rounded-md"
                      value={formData.message}
                      onChange={handleInputChange}
                    ></textarea>
                    <div className="text-start">
                      {errors.message && (
                        <span className="text-red-500">{errors.message}</span>
                      )}
                    </div>
                  </div>

                  <button
                    type="submit"
                    class="bg-[#618A00] text-[#F2F2F2] font-medium leading-7 uppercase p-2  lg:w-[562px] md:w-full w-full m-auto flex justify-center rounded-md"
                  >
                    Submit
                  </button>
                </form>
              </div>
              {/* <div className='h-[450px] lg:mt-[0px] md:mt-[0px] sm:mt-[30px] mt-[30px] flex justify-center items-center'>
<img src={Map} alt='mapimg' className='h-full '  />



            </div> */}
              <div className="lg:mt-[0px] sm:mt-[30px] mt-[30px] flex justify-center items-center">
                <GoogleMap />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
