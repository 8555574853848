import React from 'react'

function GooleMap() {
  return (
    <div><iframe src="
    https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.925923350309!2d55.34534707437951!3d25.27307722862501!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d8fcde5080d%3A0xa103a2b269d59102!2sMetasoft%20IT%20Solutions!5e0!3m2!1sen!2sin!4v1703828288998!5m2!1sen!2sin"
   height="550" className='rounded lg:w-[450px] md:w-[400px]  sm:w-[350px] w-[350px]'  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
  )
}

export default GooleMap