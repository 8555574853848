import React from "react";
import "../css/Style.css";
import FooterLogo from "../assets/img/FooterLogo.webp";
import Facebook from "../assets/img/facebook.svg";
import Instagram from "../assets/img/instagram.svg";
import Twitter from "../assets/img/twitter.svg";
import Topbutton1 from "../assets/img/Topbutton1.webp";
import Bannerlogo from "../assets/img/banner-logo.webp";
import { Link } from "react-router-dom";
function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="bg-[#222]">
      <div className="container">
        <div className="lg:flex justify-between lg:pt-[50px] p-[30px]">
          <div>
            <img src={FooterLogo} alt="Logo" />
            <div>
              <p className="text-[#9B9B9B] text-[14px] font-normal text-start leading-7 lg:w-[597px] md:w-[597px] w-full  capitalize">
                At 360 View.ai, our mission is clear: to redefine visual
                storytelling through immersive experiences. We aim to empower
                businesses and individuals with tools that captivate, engage,
                and leave a lasting impression.
              </p>
            </div>
            <div className="flex gap-5 mt-[10px]">
              <img src={Facebook} alt="facebook"></img>
              <img src={Instagram} alt="facebook"></img>
              <img src={Twitter} alt="facebook"></img>
            </div>
          </div>
          <div className="flex gap-[100px] lg:mt-[0px] md:mt-[0px] mt-5">
            <div className="">
              <p className="text-[#F2F2F2] text-[18px] font-semibold leading-10 text-start">
                Quick links
              </p>
              <p className="text-[#9B9B9B] text-[12px] font-normal leading-10 text-start">
                Home
              </p>
              <p className="text-[#9B9B9B] text-[12px] font-normal leading-10 text-start">
                About Us
              </p>
              <p className="text-[#9B9B9B] text-[12px] font-normal leading-10 text-start">
                Contact
              </p>
            </div>
            <div className="">
              <p className="text-[#F2F2F2] text-[18px] font-semibold leading-10 text-start">
                Our Services
              </p>
              <p className="text-[#9B9B9B] text-[12px] font-normal leading-10 text-start">
                Virtual tours
              </p>
              <p className="text-[#9B9B9B] text-[12px] font-normal leading-10 text-start">
                Panoramic videos
              </p>
              <p className="text-[#9B9B9B] text-[12px] font-normal leading-10 text-start">
                Immersive experiences
              </p>
            </div>
          </div>
        </div>
        <div className="flex  justify-between p-5 pb-5 items-center ">
          <div>
            <p className="text-[#CCC]  text-[12px] font-normal leading-7 text-start ">
              Copyright © 2024 360view.com
            </p>
          </div>
          <div className="flex justify-between gap-[40px] items-center">
            <p className="text-[#CCC] text-[12px] font-normal text-right">
              Privacy
            </p>
            <p className="text-[#CCC] text-[12px] font-normal text-right">
              Terms
            </p>
          </div>
        </div>
        <div className="top_btn">
          {/* <Link to="" target="_blank" className="chatWithUs">
            <img src={Bannerlogo} alt="logobanner"></img>
          </Link> */}

          <button className="myBtn1">
            <Link onClick={scrollToTop}>
              <img alt="" src={Topbutton1} />
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Footer;
