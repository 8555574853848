import React from "react";
// import About_img from "../assets/img/about_img.webp";
import img_about360view from "../assets/img/img-about360view.webp";
import Vector from "../assets/img/360view-vector.webp";
import img_about360view1 from "../assets/img/img-about360view1.webp";
import view_globe from "../assets/img/360view_globe.webp";
import homepage_gif from "../assets/img/homepage-ai-3.gif";
// import sliderimg_1 from "../assets/img/sliderimg_1.png"
// import sliderimg_2 from "../assets/img/sliderimg_2.png"
// import Slider from 'react-slick';
import Slider from "../component/slider.jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/Style.css";
// import Bg_img_about from "../assets/img/bg-img-about.webp";
import { HashLink } from "react-router-hash-link";

function About() {
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  // };

  // const CustomPrevArrow = (props) => (
  //   <div
  //     className="absolute top-1/2 left-0 transform -translate-y-1/2 cursor-pointer"
  //     onClick={props.onClick}
  //   >
  //     {/* Customize your left arrow icon or use an image */}
  //     <span className="text-2xl text-blue-500">&lt;</span>
  //   </div>
  // );

  // const CustomNextArrow = (props) => (
  //   <div
  //     className="absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer"
  //     onClick={props.onClick}
  //   >
  //     {/* Customize your right arrow icon or use an image */}
  //     <span className="text-2xl text-blue-500">&gt;</span>
  //   </div>
  // );

  return (
    <>
      <div className="aboutimg" id="aboutid">
        <div className="container">
          <div className="container">
            <div className="lg:flex justify-between lg:pt-[140px] p-[30px]">
              <div>
                <div className="flex justify-start items-center gap-5 ">
                  <p className="bg-[#95C941] w-[6px] h-[46px] rounded-xl"></p>
                  <p className="  text-[#F2F2F2] lg:text-[48px] md:text-[48px] text-[35px] font-medium ">
                    About 360 View
                  </p>
                </div>

                <div>
                  <p className="lg:w-[403px] md:w-[403px] w-[313px] pl-5 mt-4 text-start text-[#C8C8C8] text-[18px] font-normal leading-7 capitalize">
                    AI-Enhanced Quality: We guarantee top-tier, AI-enhanced 360
                    view photography.
                  </p>
                  <p className="mt-4 pl-5  lg:w-[493px] md:w-[493px] sm:w-[340px] w-[340px] text-start text-[#C8C8C8] text-[18px] font-normal leading-7 capitalize">
                    Every project undergoes rigorous quality assurance to ensure
                    your visual content not only meets but exceeds expectations.
                  </p>
                </div>
                <div className="pl-5 lg:mt-[80px] md:mt-[40px] mt-[30px] ">
                  <HashLink
                    smooth
                    to="#contact"
                    className="bg-[#618A00] w-[164px] p-1  text-[#F2F2F2] text-[16px] font-normal leading-7 rounded flex justify-center items-center"
                  >
                    Contact Now
                  </HashLink>
                </div>
              </div>
              <div className="lg:mt-[0px] md:mt-5 sm:mt-[0px] mt-5">
                <img
                  src={homepage_gif}
                  alt="about_img"
                  className="h-[380px]"
                ></img>
              </div>
            </div>
            <div className="relative  lg:mt-[160px] flex justify-center md:mt-[150px] sm:mt-[50px] mt-[50px]">
              <img
                src={img_about360view}
                className=" "
                alt="img_about360view"
              ></img>
              <div className="absolute  inset-0 flex items-center justify-center">
                <img
                  src={Vector}
                  alt="360view-vector"
                  className="lg:w-[900px] md:w-[500px] sm:w-[600px] w-[300px] m-auto"
                ></img>
              </div>
            </div>
            <div className="relative mt-[30px] flex justify-center">
              <img
                src={img_about360view1}
                className=""
                alt="img_about360view"
              ></img>
              <div className="absolute inset-0 flex items-center justify-center">
                <img src={view_globe} alt="360view-vector" className=" "></img>
              </div>
            </div>

            <Slider className="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
