import React, { useRef, useState } from "react";
import Herderbg from "../assets/img/header-bg.webp";
import Logo from "../assets/img/logo-360view.svg";
import "../css/Style.css";
import "../";
// import { IoMenu } from "react-icons/io5";
// import { Link } from "react-router-dom";
// import { IoClose } from "react-icons/io5";
import { HashLink } from "react-router-hash-link";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  // const component1Ref = useRef(null);
  // const toggleMenu = () => {
  //   setMenuOpen(!menuOpen);
  // };

  return (
    <>
      <div className="absolute top-0 w-full z-20 lg:p-[15px]">
        <div className="relative p-5 z-11">
          {/* <div className="md:hidden flex justify-end ">
                <div
                  className="text-white text-[35px] cursor-pointer"
                  onClick={toggleMenu}
                >
                  {menuOpen ? <IoClose /> : <IoMenu />}
                </div>
              </div> */}
          <ul className="flex justify-center text-start items-center z-11 text-[#F2F2F2] lg:gap-[50px] gap-[15px] lg:text-[21px] text-[14px] font-normal">
          <li>
              <HashLink
                smooth
                to="#aboutid"
                className="text-white cursor-pointer"
              >
               About Us
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="#services"
                className="text-white cursor-pointer"
              >
                Our Services
              </HashLink>
            </li>

            <li>
              <HashLink smooth to="#faq" className="text-white cursor-pointer">
                FAQ
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="#contact"
                className="text-white cursor-pointer"
              >
                Contact Us
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-cover bg-black bg-center relative">
        <img
          src={Herderbg}
          alt="headerbg"
          className="w-full h-full opacity-75 h-svh object-cover"
        ></img>

        <div className="absolute z-10 inset-0 flex items-center lg:mt-[120px]  md:mt-[50px] sm:mt-[50px] mt-[50px] justify-center">
          <div>
            <img
              src={Logo}
              alt="Logo"
              className="lg:w-full md:w-full w-[250px] mb-5"
            />

            <div className=" flex items-center justify-center lg:mt-[40px] md:mt-5 sm:mt-5 mt-0">
              <HashLink
                smooth
                to="#aboutid"
                className="bg-[#618A00] pl-[16px] pt-[8px] pb-[8px] pr-[16px] rounded text-[#F2F2F2] lg:text-[20px] md:text-[20px] sm:text-[14px] text-[14px] font-medium uppercase"
              >
                EXPLORE NOW
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
