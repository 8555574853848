import React, { useState } from "react";
import servicelogo1 from "../assets/img/servicelogo1.png";
import servicelogo2 from "../assets/img/servicelogo2.png";
import Servicesimg1 from "../assets/img/servicesimg1.webp";
import Servicesimg2 from "../assets/img/servicesimg2.webp";
import Servicesimg3 from "../assets/img/servicesimg3.webp";
import Servicebgimg from "../assets/img/servicebgimg.webp";
import Serviceimgslide1 from "../assets/img/serviceimgslide1.webp";
import Serviceimgslide2 from "../assets/img/serviceimgslide2.webp";
import Serviceimgslide3 from "../assets/img/serviceimgslide3.webp";
import pdfDownload from "../assets/pdf/360view_broucher_metasoft.pdf";

import "../css/Style.css";
function Services() {
  const [hoveredImage, setHoveredImage] = useState(1);

  return (
    <>
      <div className="bg-black m-0" id="services">
        <div className="container">
          <div className="lg:flex md:flex lg:justify-between md:justify-end sm:justify-center justify-center items-center lg:pt-[140px] p-[30px]">
            <a
              download
              href={pdfDownload}
              className="bg-[#618A00] pl-[14px] lg:ml-[100px] pt-[8px] pb-[8px] pr-[14px] rounded text-[#F2F2F2] md:text-[16px] sm:text-[14px] text-[14px] font-medium uppercase"
            >
              Download Brochure
            </a>
            <div className="flex justify-center items-center lg:pt-[0px] md:pt-[0px] pt-[40px]  gap-5">
              <p className="bg-[#95C941] w-[6px] h-[46px] rounded-xl"></p>
              <p className="  text-[#F2F2F2] lg:text-[48px] md:text-[48px] sm:text-[48px] text-[25px] font-medium ">
                Our Services
              </p>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="lg:relative ">
            <img
              src={Servicebgimg}
              alt="serviceimg"
              className="h-[700px] lg:block md:hidden sm:hidden hidden relative z-10 "
            ></img>

            <div className="bg-white lg:w-[1050px]   rounded lg:absolute  top-[50px] left-[300px]">
              <div className="lg:flex w-full lg:m-0 md:m-0 m-auto lg:p-[30px] z-1">
                <div
                  className={`image-container  ${
                    hoveredImage === 1
                      ? "lg:w-[1200px] opacity-100 w-[300px]"
                      : "lg:w-[800px] w-[300px]"
                  }`}
                  onMouseEnter={() => setHoveredImage(1)}
                >
                  <div className="image">
                    <img
                      src={hoveredImage === 1 ? Servicesimg1 : Serviceimgslide1}
                      alt="servicelogo1"
                    />
                  </div>
                  {hoveredImage === 1 ? (
                    <div className="overlay">
                      <h2 className="text-[#F2F2F2] text-[32px] font-medium uppercase mb-5 ">
                        virtual
                        <br />
                        tours
                      </h2>
                    </div>
                  ) : (
                    <div className="overlay overlay-mobile">
                      <h2 className="text-[#F2F2F2] text-[32px] font-medium uppercase mb-5 ">
                        virtual
                        <br />
                        tours
                      </h2>
                    </div>
                  )}
                </div>

                <div
                  className={`image-container  ${
                    hoveredImage === 2
                      ? "lg:w-[1200px] opacity-100 w-[300px] "
                      : "lg:w-[800px] w-[300px]"
                  }`}
                  onMouseEnter={() => setHoveredImage(2)}
                >
                  <div className="image">
                    <img
                      src={hoveredImage === 2 ? Servicesimg2 : Serviceimgslide2}
                      alt="servicelogo2"
                    />
                  </div>
                  {hoveredImage === 2 ? (
                    <div className="overlay">
                      <h2 className="text-[#F2F2F2] text-[32px] font-medium uppercase mb-5">
                        panoramic
                        <br />
                        videos
                      </h2>
                    </div>
                  ) : (
                    <div className="overlay overlay-mobile">
                      <h2 className="text-[#F2F2F2] text-[32px] font-medium uppercase mb-5">
                        panoramic
                        <br />
                        videos
                      </h2>
                    </div>
                  )}
                </div>
                <div
                  className={`image-container  ${
                    hoveredImage === 3
                      ? "lg:w-[1200px] opacity-100 w-[300px] "
                      : "lg:w-[800px] w-[300px]"
                  }`}
                  onMouseEnter={() => setHoveredImage(3)}
                >
                  <div className="image">
                    <img
                      src={hoveredImage === 3 ? Servicesimg3 : Serviceimgslide3}
                      alt="servicelogo3"
                    />
                  </div>
                  {hoveredImage === 3 ? (
                    <div className="overlay">
                      <h2 className="text-[#F2F2F2] text-[32px] font-medium uppercase mb-5">
                        immersive
                        <br />
                        experiences
                      </h2>
                    </div>
                  ) : (
                    <div className="overlay overlay-mobile">
                      <h2 className="text-[#F2F2F2] text-[32px] font-medium uppercase mb-5">
                        immersive
                        <br />
                        experiences
                      </h2>
                    </div>
                  )}
                </div>
                {/* Repeat the above structure for additional images */}
              </div>
            </div>
          </div>
        </div>

        <div className="lg:flex p-5 lg:p-0 sm:flex lg:justify-end md:justify-center sm:justify-center lg:w-full sm:w-full md:w-full w-[200px] m-auto  lg:gap-[30px] lg:pr-[100px]">
          <img
            src={servicelogo1}
            alt="servicelogo1"
            className="object-contain mb-5 lg:mb-0"
          ></img>
          <img
            src={servicelogo2}
            alt="servicelogo1"
            className="object-contain"
          ></img>
        </div>
      </div>
    </>
  );
}

export default Services;
