import React, { useEffect, useState } from "react";
import Bg_img_freequently from "../assets/img/bg-img-freequently.webp";
import { FiPlus } from "react-icons/fi";
import { HiOutlineMinusSm } from "react-icons/hi";
function Freequently() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isCollapsed1, setIsCollapsed1] = useState(true);
  const [isCollapsed2, setIsCollapsed2] = useState(true);
  const [isCollapsed3, setIsCollapsed3] = useState(true);
  const [isCollapsed4, setIsCollapsed4] = useState(true);
  const [isCollapsed5, setIsCollapsed5] = useState(true);
  const [isCollapsed6, setIsCollapsed6] = useState(true);
  const [isCollapsed7, setIsCollapsed7] = useState(true);
  const [isCollapsed8, setIsCollapsed8] = useState(true);
  const [isCollapsed9, setIsCollapsed9] = useState(true);
  //   const [isCollapsed10, setIsCollapsed10] = useState(true);
  //   const [isCollapsed11, setIsCollapsed11] = useState(true);
  //   const [isCollapsed12, setIsCollapsed12] = useState(true);
  //   const [isCollapsed13, setIsCollapsed13] = useState(true);
  //   const [isCollapsed14, setIsCollapsed14] = useState(true);
  //   const [isCollapsed15, setIsCollapsed15] = useState(true);
  //   const [isCollapsed16, setIsCollapsed16] = useState(true);
  //   const [isCollapsed17, setIsCollapsed17] = useState(true);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const toggleCollapse1 = () => {
    setIsCollapsed1(!isCollapsed1);
  };
  const toggleCollapse2 = () => {
    setIsCollapsed2(!isCollapsed2);
  };
  const toggleCollapse3 = () => {
    setIsCollapsed3(!isCollapsed3);
  };
  const toggleCollapse4 = () => {
    setIsCollapsed4(!isCollapsed4);
  };
  const toggleCollapse5 = () => {
    setIsCollapsed5(!isCollapsed5);
  };
  const toggleCollapse6 = () => {
    setIsCollapsed6(!isCollapsed6);
  };
  const toggleCollapse7 = () => {
    setIsCollapsed7(!isCollapsed7);
  };
  const toggleCollapse8 = () => {
    setIsCollapsed8(!isCollapsed8);
  };
  const toggleCollapse9 = () => {
    setIsCollapsed9(!isCollapsed9);
  };
  //   const toggleCollapse10 = () => {
  //     setIsCollapsed10(!isCollapsed10);
  //   };
  //   const toggleCollapse11 = () => {
  //     setIsCollapsed11(!isCollapsed11);
  //   };
  //   const toggleCollapse12 = () => {
  //     setIsCollapsed12(!isCollapsed12);
  //   };
  //   const toggleCollapse13 = () => {
  //     setIsCollapsed13(!isCollapsed13);
  //   };
  //   const toggleCollapse14 = () => {
  //     setIsCollapsed14(!isCollapsed14);
  //   };
  //   const toggleCollapse15 = () => {
  //     setIsCollapsed15(!isCollapsed15);
  //   };
  //   const toggleCollapse16 = () => {
  //     setIsCollapsed16(!isCollapsed16);
  //   };
  //   const toggleCollapse17 = () => {
  //     setIsCollapsed17(!isCollapsed17);
  //   };
  return (
    <div className="bgimgfreequently object-fit" id="faq">
      {/* <img src={Bg_img_freequently} alt='bg-about' className="w-full lg:h-full md:h-[1000px] sm:h-[850px] h-[890px]"></img> */}
      <div class="container">
        <div className="">
          <div className="flex lg:justify-center md:justify-center justify-center items-center gap-3 lg:pt-[60px] ">
            <p className="bg-[#95C941] w-[6px] h-[46px] rounded-xl "></p>
            <p className="  text-[#F2F2F2] lg:text-[48px] md:text-[42px] text-[25px] text-start flex items-center font-medium ">
              Frequently Asked Questions
            </p>
          </div>
          <div className="lg:mt-[80px] md:mt-[80px] mt-[40px] lg:w-[750px] lg:m-auto md:w-full md:m-auto w-full">
            <div className="flex justify-between pb-3 lg:border-b md:border-b  border-[#9B9B9B] ">
              <h2
                onClick={toggleCollapse}
                className="text-[16px] font-semibold text-start text-[#9B9B9B] cursor-pointer"
              >
                What exactly is 360 view photography? {isCollapsed1 ? "" : ""}
              </h2>
              <button
                onClick={toggleCollapse}
                className="text-[25px]  text-[#9B9B9B] font-medium"
              >
                {isCollapsed ? <FiPlus /> : <HiOutlineMinusSm />}
              </button>
            </div>
            {isCollapsed ? null : (
              <div>
                <p className="mt-4 text-[14px] text-[#9B9B9B] text-start">
                  360 view photography captures a complete view of a scene or
                  environment in a 360-degree perspective. It allows viewers to
                  interactively explore and navigate through images, providing a
                  more immersive experience.
                </p>
              </div>
            )}
          </div>
          <div className="pt-[20px] lg:w-[750px] lg:m-auto md:w-full md:m-auto w-full">
            <div className="flex justify-between pb-3 lg:border-b md:border-b border-[#9B9B9B] ">
              <h2
                onClick={toggleCollapse1}
                className="text-[16px] font-semibold text-start text-[#9B9B9B] cursor-pointer	"
              >
                How is a 360 view photograph created? {isCollapsed1 ? "" : ""}
              </h2>
              <button
                onClick={toggleCollapse1}
                className="text-[25px]  text-[#9B9B9B] font-medium"
              >
                {isCollapsed ? <FiPlus /> : <HiOutlineMinusSm />}
              </button>
            </div>
            {isCollapsed1 ? null : (
              <div>
                <p className="mt-4 text-[14px] text-[#9B9B9B] text-start">
                  A 360 view photograph is created by capturing multiple images
                  from different angles and stitching them together using
                  specialized software. This process creates a seamless and
                  panoramic view of the entire surroundings.
                </p>
              </div>
            )}
          </div>
          <div className="pt-[20px] lg:w-[750px] lg:m-auto md:w-full md:m-auto w-full">
            <div className="flex justify-between pb-3 lg:border-b md:border-b border-[#9B9B9B] ">
              <h2
                onClick={toggleCollapse2}
                className="text-[16px] font-semibold text-start text-[#9B9B9B] cursor-pointer	"
              >
                What kind of camera is required for 360 view photography?{" "}
              </h2>
              <button
                onClick={toggleCollapse2}
                className="text-[25px]  text-[#9B9B9B] font-medium"
              >
                {isCollapsed ? <FiPlus /> : <HiOutlineMinusSm />}
              </button>
            </div>
            {isCollapsed2 ? null : (
              <div>
                <p className="mt-4 text-[14px] text-[#9B9B9B] text-start">
                  While specialized 360 cameras are available, you can also
                  create 360 views using a DSLR or smartphone equipped with
                  panoramic capabilities. The key is to capture images from all
                  angles to ensure a complete view.
                </p>
              </div>
            )}
          </div>
          <div className="pt-[20px] lg:w-[750px] lg:m-auto md:w-full md:m-auto w-full">
            <div className="flex justify-between pb-3  lg:border-b md:border-b border-[#9B9B9B] ">
              <h2
                onClick={toggleCollapse3}
                className="text-[16px] font-semibold text-start text-[#9B9B9B] cursor-pointer	"
              >
                Where is 360 view photography commonly used?{" "}
              </h2>
              <button
                onClick={toggleCollapse3}
                className="text-[25px]  text-[#9B9B9B] font-medium"
              >
                {isCollapsed ? <FiPlus /> : <HiOutlineMinusSm />}
              </button>
            </div>
            {isCollapsed3 ? null : (
              <div>
                <p className="mt-4 text-[14px] text-[#9B9B9B] text-start">
                  360 view photography finds applications in various industries,
                  including real estate, e-commerce, tourism, and virtual tours.
                  It's a powerful tool for showcasing products, properties, and
                  environments in an interactive way.
                </p>
              </div>
            )}
          </div>
          <div className="pt-[20px] lg:w-[750px] lg:m-auto md:w-full md:m-auto w-full">
            <div className="flex justify-between pb-3 lg:border-b md:border-b border-[#9B9B9B] ">
              <h2
                onClick={toggleCollapse4}
                className="text-[16px] font-semibold text-start text-[#9B9B9B] cursor-pointer	"
              >
                How does 360 view photography enhance the user experience?{" "}
              </h2>
              <button
                onClick={toggleCollapse4}
                className="text-[25px]  text-[#9B9B9B] font-medium"
              >
                {isCollapsed ? <FiPlus /> : <HiOutlineMinusSm />}
              </button>
            </div>
            {isCollapsed4 ? null : (
              <div>
                <p className="mt-4 text-[14px] text-[#9B9B9B] text-start">
                  360 view photography enhances user experience by allowing
                  viewers to virtually "walk through" a space or examine a
                  product from every angle. It engages users and provides a more
                  comprehensive understanding of the subject.
                </p>
              </div>
            )}
          </div>
          <div className="pt-[20px] lg:w-[750px] lg:m-auto md:w-full md:m-auto w-full">
            <div className="flex justify-between  pb-3 lg:border-b md:border-b border-[#9B9B9B] ">
              <h2
                onClick={toggleCollapse5}
                className="text-[16px] font-semibold text-start  text-[#9B9B9B] cursor-pointer	"
              >
                Can 360 view photography be integrated with websites and social
                media?
              </h2>
              <button
                onClick={toggleCollapse5}
                className="text-[25px]  text-[#9B9B9B] font-medium"
              >
                {isCollapsed ? <FiPlus /> : <HiOutlineMinusSm />}
              </button>
            </div>
            {isCollapsed5 ? null : (
              <div>
                <p className="mt-4 text-[14px] text-[#9B9B9B] text-[#9B9B9B] text-start">
                  Yes, 360 view photography is easily integrated into websites
                  and social media platforms. Many platforms support 360 images
                  and videos, providing an opportunity to showcase immersive
                  content to a broader audience.
                </p>
              </div>
            )}
          </div>
          <div className="pt-[20px] lg:w-[750px] lg:m-auto md:w-full md:m-auto w-full">
            <div className="flex justify-between pb-3 lg:border-b md:border-b border-[#9B9B9B] ">
              <h2
                onClick={toggleCollapse6}
                className="text-[16px] font-semibold text-start text-[#9B9B9B] cursor-pointer	"
              >
                Are there specific industries that benefit the most from 360
                view photography?
              </h2>
              <button
                onClick={toggleCollapse6}
                className="text-[25px]  text-[#9B9B9B] font-medium"
              >
                {isCollapsed ? <FiPlus /> : <HiOutlineMinusSm />}
              </button>
            </div>
            {isCollapsed6 ? null : (
              <div>
                <p className="mt-4 text-[14px] text-[#9B9B9B] text-start">
                  Industries such as real estate, hospitality, e-commerce, and
                  travel benefit significantly from 360 view photography. It
                  helps in showcasing properties, products, and destinations in
                  a more compelling and interactive manner.
                </p>
              </div>
            )}
          </div>
          <div className="pt-[20px] lg:w-[750px] lg:m-auto md:w-full md:m-auto w-full">
            <div className="flex justify-between pb-3  lg:border-b md:border-b border-[#9B9B9B] ">
              <h2
                onClick={toggleCollapse7}
                className="text-[16px] font-semibold text-start text-[#9B9B9B] cursor-pointer	"
              >
                How does 360 view photography contribute to marketing and sales?{" "}
              </h2>
              <button
                onClick={toggleCollapse7}
                className="text-[25px]  text-[#9B9B9B] font-medium"
              >
                {isCollapsed ? <FiPlus /> : <HiOutlineMinusSm />}
              </button>
            </div>
            {isCollapsed7 ? null : (
              <div>
                <p className="mt-4 text-[14px] text-[#9B9B9B] text-start">
                  360 view photography contributes to marketing and sales by
                  increasing engagement and customer confidence. For example, in
                  e-commerce, it reduces the uncertainty of online shopping by
                  allowing customers to examine products in detail before making
                  a purchase decision.
                </p>
              </div>
            )}
          </div>
          <div className="pt-[20px] lg:w-[750px] lg:m-auto md:w-full md:m-auto w-full">
            <div className="flex justify-between pb-3 lg:border-b md:border-b border-[#9B9B9B] ">
              <h2
                onClick={toggleCollapse8}
                className="text-[16px] font-semibold text-start text-[#9B9B9B] cursor-pointer	"
              >
                Can 360 view photography be used for virtual reality (VR)
                experiences?
              </h2>
              <button
                onClick={toggleCollapse8}
                className="text-[25px]  text-[#9B9B9B] font-normal"
              >
                {isCollapsed ? <FiPlus /> : <HiOutlineMinusSm />}
              </button>
            </div>
            {isCollapsed8 ? null : (
              <div>
                <p className="mt-4 text-[14px] text-[#9B9B9B] text-start">
                  Yes, 360 view photography is often utilized in VR experiences.
                  When combined with VR technology, it provides users with a
                  more immersive and realistic virtual environment, enhancing
                  the overall virtual reality experience.
                </p>
              </div>
            )}
          </div>
          <div className="pt-[20px] lg:w-[750px] lg:m-auto md:w-full md:m-auto w-full">
            <div className="flex justify-between pb-3 lg:border-b md:border-b border-[#9B9B9B] ">
              <h2
                onClick={toggleCollapse9}
                className="text-[16px] font-semibold text-start text-[#9B9B9B] cursor-pointer	"
              >
                What are the SEO benefits of using 360 view photography on a
                website?
              </h2>
              <button
                onClick={toggleCollapse9}
                className=" text-[25px]  text-[#9B9B9B] font-normal"
              >
                {isCollapsed ? <FiPlus /> : <HiOutlineMinusSm />}
              </button>
            </div>
            {isCollapsed9 ? null : (
              <div>
                <p className="mt-4 text-[14px] text-[#9B9B9B] text-start">
                  Incorporating 360 view photography on a website can improve
                  SEO by increasing user engagement, reducing bounce rates, and
                  providing unique and shareable content. Optimizing image
                  descriptions and metadata also contributes to better search
                  engine visibility.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Freequently;
