import React, { useRef } from 'react'
import About from "../component/About.jsx"
import Header from "../component/Header.jsx"
import Banner from "../component/Banner.jsx"
import Services from "../component/Services.jsx"
import Features from "../component/Features.jsx"
import Testimonail from "../component/Testimonial.jsx"
import Frequently from "../component/Freequently.jsx"
import Gallery from './Gallery.jsx'
import Contact from './Contact.jsx'
import Footer from './Footer.jsx'
// import Processbar from './processbar.jsx'
function Home() {
  
 
  return (
   <>
    <Header   />
    <Banner />
    <Services />
    <Features />
    <About />
    <Testimonail />
    <Frequently />
    <Gallery />
    <Contact />
    <Footer />
   </>
  )
}

export default Home