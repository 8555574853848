import React from "react";
// import Testimonailbg from "../assets/img/testimonailbg.webp";
import Testimonialellipse from "../assets/img/testimonialellipse.webp";

function Testimonial() {
  return (
    <>
      <div className="testimonialbg">
        {/* <img src={Testimonailbg} alt='Testimonail' className='w-full lg:h-full sm:h-[1200px] h-[1500px] md:h-[1400px]'></img> */}
        <div className="">
          <div className="inset-0 container pt-[90px] pb-[90px]">
            <div className="flex justify-center  items-center gap-5">
              <p className="bg-[#95C941] w-[6px] h-[46px] rounded-xl"></p>
              <p className="  text-[#F2F2F2] lg:text-[48px] md:text-[48px] sm:text-[48px] text-[35px] font-medium ">
                Testimonials
              </p>
            </div>
            <div className="lg:flex   justify-center gap-5 mt-[50px] items-center">
              <div className="bg-black p-5 lg:m-[0px] md:m-[0px] w-full lg:h-[400px] h-full rounded">
                <div className="flex gap-5  items-center">
                  <img src={Testimonialellipse} alt="testminaleclipse"></img>
                  <div className="text-start">
                    <p className="text-[#F2F2F2]  text-[18px] font-normal capitalize leading-7">
                      Henry Sam
                    </p>
                    <p className="text-[#9B9B9B] text-[18px] font-medium capitalize">
                      Real estate agent
                    </p>
                  </div>
                </div>
                <p className="lg:w-[340px] md:w-[340px] w-full text-start mt-5 text-[#9B9B9B] text-[18px] font-medium capitalize">
                  360 view has completely transformed the way we showcase our
                  properties to potential buyers. With their 3D virtual tours,
                  we can give clients a realistic and immersive experience of
                  our listings, which has led to a significant increase in
                  sales.
                </p>
                <div className="flex justify-end items-center gap-1">
                  <svg
                    width="27"
                    height="48"
                    viewBox="0 0 27 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.4666 16.6325C26.3924 21.0632 25.4215 25.2597 23.7231 29.2902C22.3665 32.5101 20.622 35.4761 18.2256 37.9856C16.6157 39.6711 14.7759 41.0707 12.8726 42.3795C10.2093 44.2107 7.42651 45.8171 4.42747 46.9786C3.46712 47.3506 2.44929 47.5754 1.44507 47.808C1.01177 47.9083 0.51042 47.931 0.223068 47.4383C-0.0846995 46.9097 -0.0763796 46.3387 0.26693 45.8484C0.581503 45.3996 0.933888 44.9422 1.36416 44.6258C2.73891 43.6131 4.17415 42.6889 5.55419 41.684C6.74821 40.8138 8.00197 39.9906 9.04551 38.9497C10.7946 37.2055 12.4476 35.3422 13.6242 33.1045C14.2624 31.8913 14.4999 30.6178 13.6704 29.4061C13.2847 28.843 12.7879 28.2994 12.226 27.9407C11.029 27.1763 9.77372 26.5035 8.51164 25.8573C6.98339 25.0741 5.49143 24.2415 4.13559 23.1615C1.79519 21.2966 0.579236 18.82 0.350867 15.7944C0.231389 14.2076 0.0650303 12.6216 0.261638 11.0277C0.758453 6.99725 2.66253 3.89646 6.04345 1.83423C11.1265 -1.26578 17.6751 -0.424596 22.0625 4.26458C24.2003 6.55003 25.4026 9.40567 25.9592 12.5253C26.2011 13.8802 26.3017 15.2626 26.4666 16.6333L26.4666 16.6325Z"
                      fill="#B5D666"
                    />
                  </svg>
                  <svg
                    width="27"
                    height="48"
                    viewBox="0 0 27 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.4666 16.6325C26.3924 21.0632 25.4215 25.2597 23.7231 29.2902C22.3665 32.5101 20.622 35.4761 18.2256 37.9856C16.6157 39.6711 14.7759 41.0707 12.8726 42.3795C10.2093 44.2107 7.42651 45.8171 4.42747 46.9786C3.46712 47.3506 2.44929 47.5754 1.44507 47.808C1.01177 47.9083 0.51042 47.931 0.223068 47.4383C-0.0846995 46.9097 -0.0763796 46.3387 0.26693 45.8484C0.581503 45.3996 0.933888 44.9422 1.36416 44.6258C2.73891 43.6131 4.17415 42.6889 5.55419 41.684C6.74821 40.8138 8.00197 39.9906 9.04551 38.9497C10.7946 37.2055 12.4476 35.3422 13.6242 33.1045C14.2624 31.8913 14.4999 30.6178 13.6704 29.4061C13.2847 28.843 12.7879 28.2994 12.226 27.9407C11.029 27.1763 9.77372 26.5035 8.51164 25.8573C6.98339 25.0741 5.49143 24.2415 4.13559 23.1615C1.79519 21.2966 0.579236 18.82 0.350867 15.7944C0.231389 14.2076 0.0650303 12.6216 0.261638 11.0277C0.758453 6.99725 2.66253 3.89646 6.04345 1.83423C11.1265 -1.26578 17.6751 -0.424596 22.0625 4.26458C24.2003 6.55003 25.4026 9.40567 25.9592 12.5253C26.2011 13.8802 26.3017 15.2626 26.4666 16.6333L26.4666 16.6325Z"
                      fill="#B5D666"
                    />
                  </svg>
                </div>
              </div>
              <div className="bg-black p-5 rounded lg:m-[0px] lg:h-[400px] h-full md:m-[0px] mt-5">
                <div className=" flex gap-5 items-center">
                  <img src={Testimonialellipse} alt="testminaleclipse"></img>
                  <div className="text-start">
                    <p className="text-[#F2F2F2] text-[18px] font-normal capitalize leading-7">
                      Mark Rob
                    </p>
                    <p className="text-[#9B9B9B] text-[18px] font-medium capitalize">
                      Construction manager
                    </p>
                  </div>
                </div>
                <p className="lg:w-[340px] md:w-[340px] w-full text-start mt-5 text-[#9B9B9B] text-[18px] font-medium capitalize">
                  360 View has been a game-changer for our construction
                  business. We can now easily document progress on projects and
                  share 3D models with clients and stakeholders, which has
                  improved communication and collaboration.
                </p>
                <div className="flex justify-end items-center gap-1 mt-[30px]">
                  <svg
                    width="27"
                    height="48"
                    viewBox="0 0 27 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.4666 16.6325C26.3924 21.0632 25.4215 25.2597 23.7231 29.2902C22.3665 32.5101 20.622 35.4761 18.2256 37.9856C16.6157 39.6711 14.7759 41.0707 12.8726 42.3795C10.2093 44.2107 7.42651 45.8171 4.42747 46.9786C3.46712 47.3506 2.44929 47.5754 1.44507 47.808C1.01177 47.9083 0.51042 47.931 0.223068 47.4383C-0.0846995 46.9097 -0.0763796 46.3387 0.26693 45.8484C0.581503 45.3996 0.933888 44.9422 1.36416 44.6258C2.73891 43.6131 4.17415 42.6889 5.55419 41.684C6.74821 40.8138 8.00197 39.9906 9.04551 38.9497C10.7946 37.2055 12.4476 35.3422 13.6242 33.1045C14.2624 31.8913 14.4999 30.6178 13.6704 29.4061C13.2847 28.843 12.7879 28.2994 12.226 27.9407C11.029 27.1763 9.77372 26.5035 8.51164 25.8573C6.98339 25.0741 5.49143 24.2415 4.13559 23.1615C1.79519 21.2966 0.579236 18.82 0.350867 15.7944C0.231389 14.2076 0.0650303 12.6216 0.261638 11.0277C0.758453 6.99725 2.66253 3.89646 6.04345 1.83423C11.1265 -1.26578 17.6751 -0.424596 22.0625 4.26458C24.2003 6.55003 25.4026 9.40567 25.9592 12.5253C26.2011 13.8802 26.3017 15.2626 26.4666 16.6333L26.4666 16.6325Z"
                      fill="#B5D666"
                    />
                  </svg>
                  <svg
                    width="27"
                    height="48"
                    viewBox="0 0 27 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.4666 16.6325C26.3924 21.0632 25.4215 25.2597 23.7231 29.2902C22.3665 32.5101 20.622 35.4761 18.2256 37.9856C16.6157 39.6711 14.7759 41.0707 12.8726 42.3795C10.2093 44.2107 7.42651 45.8171 4.42747 46.9786C3.46712 47.3506 2.44929 47.5754 1.44507 47.808C1.01177 47.9083 0.51042 47.931 0.223068 47.4383C-0.0846995 46.9097 -0.0763796 46.3387 0.26693 45.8484C0.581503 45.3996 0.933888 44.9422 1.36416 44.6258C2.73891 43.6131 4.17415 42.6889 5.55419 41.684C6.74821 40.8138 8.00197 39.9906 9.04551 38.9497C10.7946 37.2055 12.4476 35.3422 13.6242 33.1045C14.2624 31.8913 14.4999 30.6178 13.6704 29.4061C13.2847 28.843 12.7879 28.2994 12.226 27.9407C11.029 27.1763 9.77372 26.5035 8.51164 25.8573C6.98339 25.0741 5.49143 24.2415 4.13559 23.1615C1.79519 21.2966 0.579236 18.82 0.350867 15.7944C0.231389 14.2076 0.0650303 12.6216 0.261638 11.0277C0.758453 6.99725 2.66253 3.89646 6.04345 1.83423C11.1265 -1.26578 17.6751 -0.424596 22.0625 4.26458C24.2003 6.55003 25.4026 9.40567 25.9592 12.5253C26.2011 13.8802 26.3017 15.2626 26.4666 16.6333L26.4666 16.6325Z"
                      fill="#B5D666"
                    />
                  </svg>
                </div>
              </div>
              <div className="bg-black p-5 rounded lg:m-[0px] lg:h-[400px] h-full md:m-[0px] mt-5">
                <div className=" flex gap-5 items-center">
                  <img src={Testimonialellipse} alt="testminaleclipse"></img>
                  <div className="text-start">
                    <p className="text-[#F2F2F2] text-[18px] font-normal capitalize leading-7">
                      John Alex
                    </p>
                    <p className="text-[#9B9B9B] text-[18px] font-medium capitalize">
                      Facilities manager
                    </p>
                  </div>
                </div>
                <p className="lg:w-[340px] md:w-[340px] w-full text-start mt-5 text-[#9B9B9B] text-[18px] font-medium capitalize">
                  360 view has helped us save time and money on facilities
                  management. We can now use their 3D models to track assets,
                  identify maintenance issues, and plan renovations.
                </p>
                <div className="flex justify-end gap-1 items-center mt-[50px]">
                  <svg
                    width="27"
                    height="48"
                    viewBox="0 0 27 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.4666 16.6325C26.3924 21.0632 25.4215 25.2597 23.7231 29.2902C22.3665 32.5101 20.622 35.4761 18.2256 37.9856C16.6157 39.6711 14.7759 41.0707 12.8726 42.3795C10.2093 44.2107 7.42651 45.8171 4.42747 46.9786C3.46712 47.3506 2.44929 47.5754 1.44507 47.808C1.01177 47.9083 0.51042 47.931 0.223068 47.4383C-0.0846995 46.9097 -0.0763796 46.3387 0.26693 45.8484C0.581503 45.3996 0.933888 44.9422 1.36416 44.6258C2.73891 43.6131 4.17415 42.6889 5.55419 41.684C6.74821 40.8138 8.00197 39.9906 9.04551 38.9497C10.7946 37.2055 12.4476 35.3422 13.6242 33.1045C14.2624 31.8913 14.4999 30.6178 13.6704 29.4061C13.2847 28.843 12.7879 28.2994 12.226 27.9407C11.029 27.1763 9.77372 26.5035 8.51164 25.8573C6.98339 25.0741 5.49143 24.2415 4.13559 23.1615C1.79519 21.2966 0.579236 18.82 0.350867 15.7944C0.231389 14.2076 0.0650303 12.6216 0.261638 11.0277C0.758453 6.99725 2.66253 3.89646 6.04345 1.83423C11.1265 -1.26578 17.6751 -0.424596 22.0625 4.26458C24.2003 6.55003 25.4026 9.40567 25.9592 12.5253C26.2011 13.8802 26.3017 15.2626 26.4666 16.6333L26.4666 16.6325Z"
                      fill="#B5D666"
                    />
                  </svg>
                  <svg
                    width="27"
                    height="48"
                    viewBox="0 0 27 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.4666 16.6325C26.3924 21.0632 25.4215 25.2597 23.7231 29.2902C22.3665 32.5101 20.622 35.4761 18.2256 37.9856C16.6157 39.6711 14.7759 41.0707 12.8726 42.3795C10.2093 44.2107 7.42651 45.8171 4.42747 46.9786C3.46712 47.3506 2.44929 47.5754 1.44507 47.808C1.01177 47.9083 0.51042 47.931 0.223068 47.4383C-0.0846995 46.9097 -0.0763796 46.3387 0.26693 45.8484C0.581503 45.3996 0.933888 44.9422 1.36416 44.6258C2.73891 43.6131 4.17415 42.6889 5.55419 41.684C6.74821 40.8138 8.00197 39.9906 9.04551 38.9497C10.7946 37.2055 12.4476 35.3422 13.6242 33.1045C14.2624 31.8913 14.4999 30.6178 13.6704 29.4061C13.2847 28.843 12.7879 28.2994 12.226 27.9407C11.029 27.1763 9.77372 26.5035 8.51164 25.8573C6.98339 25.0741 5.49143 24.2415 4.13559 23.1615C1.79519 21.2966 0.579236 18.82 0.350867 15.7944C0.231389 14.2076 0.0650303 12.6216 0.261638 11.0277C0.758453 6.99725 2.66253 3.89646 6.04345 1.83423C11.1265 -1.26578 17.6751 -0.424596 22.0625 4.26458C24.2003 6.55003 25.4026 9.40567 25.9592 12.5253C26.2011 13.8802 26.3017 15.2626 26.4666 16.6333L26.4666 16.6325Z"
                      fill="#B5D666"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial;
